/**
* This function is used to get event date display.
* @param val - UTC time to be converted.
* @param live - boolean to check if the event is live
*/
export function getEventDayTime(val,live){
    var time_display = "";
   // var today = new Date();
    var UTC_date = new Date(val).toISOString();
    if(UTC_date!=="Invalid Date"){
    var localTimeZone = new Date(UTC_date);
    var hours = localTimeZone.getHours();
    var minutes = localTimeZone.getMinutes();
    var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
    if(hours<10) {
        hours='0'+hours;
    }
    if(minutes<10) {
        minutes='0'+minutes;
    }
    if(!live){
        var date = localTimeZone.getDate();
        var month = localTimeZone.getMonth();
      //  var year = localTimeZone.getFullYear();
        if(date<10) {
            date='0'+date;
        }
        // if(month<10) {
        //     month='0'+month;
        // }
      //  var dayDifference = localTimeZone.getDate() - today.getDate();
       
        time_display = date+" "+monthShortNames[month]+" "+"/ "+hours+":"+minutes;
        // if(dayDifference === 0){
        //     time_display = "Today "+hours+":"+minutes;
        // }
        // else if(dayDifference === 1){
        //     time_display = "Tomorrow "+hours+":"+minutes;
        // }
        // else{
           
        // }
    }
    else{
        time_display = hours+":"+minutes;
    }
    return time_display;
}
}