import React from 'react';
import './../src/assets/css/sportsInsights-styles.scss';
import Multibet from './widget/mutlibet';

function App() {
  return (
    <div className="sportsInsights-betGeneratorWidget">
  < Multibet/>
  </div>
  );
}

export default App;
